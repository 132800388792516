.content-counter ol {
    list-style: none;
    counter-reset: li;
}

.content-counter li:before {
    counter-increment: li;
    content: counters(li, ".") ". ";
}

.anchor-link {
    color: #0d6efd;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}